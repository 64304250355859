class Score {
  constructor() {
    this.score
    this.scoreLoaded = false
    this.scoreLength = 0
    this.index = 0
    // this.onload // score is loaded callback

    fetch(localized.template_url + "/data/data.json")
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        this.score = data
        this.scoreLength = Object.keys(this.score).length
        // console.log("score length: ", this.scoreLength)
        document.querySelector(".play-button").classList.add("loaded")
        this.scoreLoaded = true
        this.onload()
      })
      .catch(error => console.log(error))
  }

  onload() {}

  randomIndex() {
    this.index = Math.floor(this.scoreLength * Math.random())
    return this.index
  }

  nextIndex(index) {
    index++
    if (index >= this.scoreLength) {
      index = 0
    }
    return index
  }

  randomIndexForLocationAndChannel(loc, channel) {
    let index = this.randomIndex()
    // console.log(index)
    while (!this.score[index].hasOwnProperty("loc") || this.score[index].loc !== loc || !this.score[index].hasOwnProperty(channel)) {
      //   index = this.nextIndex(index)
      index = this.randomIndex()
      //   console.log(index)
    }
    this.index = index
    // console.log(`Found random index for location and channel: ${loc}/${channel}`, index, this.score[index])
    return index
  }

  nextIndexForLocationAndChannel(loc, channel) {
    let index = this.index
    do {
      index = this.nextIndex(index)
    } while (!this.score[index].hasOwnProperty("loc") || this.score[index].loc !== loc || !this.score[index].hasOwnProperty(channel))
    this.index = index
    // console.log(`Found next index for location and channel: ${loc}/${channel}`, index, this.score[index])
    return index
  }

  nextIndexForLocationEnvData(loc) {
    let index = this.index

    while (this.score[index].loc !== loc || !this.score[index].temperature || (loc === "littoral-1" && !this.score[index].hasOwnProperty("temp_water") && !this.score[index].hasOwnProperty("salinity"))) {
      index = this.nextIndex(index)
    }
    // console.log("env data", index, this.score[index])
    return index
  }

  nextIndexForWaterData() {
    let index = this.index
    do {
      index = this.nextIndex(index)
    } while (!this.score[index].hasOwnProperty("temp_water") && !this.score[index].hasOwnProperty("salinity"))
    // console.log("water data", index, this.score[index])
    return index
  }
}

export default Score
