import * as Tone from "tone"

class AudioPlayer {
  constructor(audioContext, filename, attack, decay) {
    this.audioContext = audioContext
    this.numberOfElements = 2
    this.activeElement = 0
    this.inactiveElement = 1
    this.attack = attack || 2
    this.decay = decay || 4

    // with tone js
    this.players = []
    this.gain = new Tone.Gain(0)
    this.startTime = 0
    this.offset = 0
    this.timeout

    this.onstop // on stop callback
    this.onerror // on error callback

    this.duration = 0 // length of sample of active player
    this.currentTime = 0
    this.timeupdateInterval
    this.timeupdate // time update callback

    for (let i = 0; i < this.numberOfElements; i++) {
      this.players[i] = new Tone.Player({
        // url is set when player starts -> avoid onerror, if url invalid
        // url: filename,
        fadeIn: this.attack,
        fadeOut: this.decay,
        onstop: () => {
          // console.log("audioplayer: player " + i + " stopped");

          if (i === this.activeElement) {
            // clearInterval(this.timeupdateInterval);
            this.onstop(i)
          }
        },
        onerror: err => {
          if (i === this.activeElement) {
            // console.log("audioplayer " + i + ": onerror", err)
            this.onerror(i)
          }
        },
        onload: () => {
          if (i === this.activeElement) {
            // console.log("audioplayer: player loaded", i);
            // this.onload(i);
          }
        }
      })

      this.players[i].connect(this.gain)
      this.gain.toDestination(audioContext)
    }
  }

  start(offset) {
    this.resumeAudioContext()

    this.gain.gain.rampTo(1, 0.1)

    this.players[this.activeElement].start(0, offset ? offset : 0)
    this.players[this.inactiveElement].stop()

    this.startTime = Tone.now()
    this.offset = offset ? offset : 0

    clearInterval(this.timeupdateInterval)
    this.timeupdateInterval = setInterval(() => {
      this.currentTime = Tone.now() - this.startTime + this.offset
      this.timeupdate(this.currentTime)
    }, 1000)

    // console.log("audioplayer: start( " + offset + " )", "active el:", this.activeElement)
  }

  stop() {
    clearInterval(this.timeupdateInterval)

    for (let i = 0; i < this.players.length; i++) {
      this.players[i].stop()
    }

    this.gain.gain.rampTo(0, 0.1)

    this.audioContext.suspend()

    // console.log("audioplayer: stop")
  }

  getTime() {
    return this.players[this.activeElement].now()
  }

  setTime(time) {
    this.resumeAudioContext()
    this.players[this.activeElement].seek(time)
  }

  setSource(filename) {
    // change between the two audio elements for smooth playback
    this.inactiveElement = this.activeElement
    this.activeElement++
    if (this.activeElement >= this.numberOfElements) this.activeElement = 0

    // returns a promise
    const promise = this.players[this.activeElement].buffer.load(filename).then(() => {
      this.duration = this.players[this.activeElement].buffer.duration
      // console.log("audioplayer: set source ok", this.duration);
    })

    return promise
  }

  fadeIn(element) {
    // this.gains[element].gain.rampTo(1, this.attack);
    this.gains[element].gain.rampTo(1, 0)
  }

  fadeOut(element) {
    this.gains[element].gain.rampTo(0, this.decay)
  }

  resumeAudioContext() {
    if (this.audioContext.state === "suspended") {
      this.audioContext.resume()
      // console.log("audioplayer: conntext resumed")
    }
  }
}

export default AudioPlayer
