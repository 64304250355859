import "./editor.scss"
import "../css/style.scss"

// Our modules / classes
// import MobileMenu from "./modules/MobileMenu"

// Instantiate a new object using our modules/classes
// const mobileMenu = new MobileMenu()

import ImagesLoaded from "imagesloaded"
import Lazyload from "lazyload"
import Swiper from "swiper/bundle"
// import Swiper, { Navigation } from "swiper/bundle"
import { DateTime, FixedOffsetZone } from "luxon"

// Our modules / classes
import LoadMorePosts from "./modules/LoadMorePosts"
let loadMorePosts
import Score from "./modules/Score"
import AudioPlayer from "./modules/AudioPlayer"
import * as Tone from "tone"

var debug = true
const mobileBreakpoint = 782
let mobileView = false

const ScrollDelta = 10 // how many pixels to scroll to call scroll dependent function

let adminBarHeight = 0
let siteTitleHeight = 0
let titleHeight = 0
let navHeight = 0

let historyUpdate = false // to push to history or not
let autoplayOn = true
let autoplaySpeed = 800
let autoplayDelay = 3000
let orientationLandscape = true
let shuffleInterval
let startAutoShuffleTimeout

let lastScroll = 0
let prevScrollDelta = 0
let scrollYPositions = {}

let currentContentID = -1
let prevContentID = -1
let navOpen = false // initially closed
let navChanging = false
let navChangingTimeout = null
const navChangingTime = 400
let autoScrolling = false

let requestAllPartsInterval = null
let nowWithKeyboard = false // if true mobile keyboard is showing
let observer
const fixedZone = new FixedOffsetZone(0) // timestamp strings are in wintertime only gmt

// get data.json file
const score = new Score()

jQuery(function ($) {
  // if (debug) console.log("document ready!")
  const $html = $("html")
  $html.addClass("js") // js is working

  if (checkFlexGap()) {
    document.documentElement.classList.add("flexbox-gap")
  } else {
    document.documentElement.classList.add("no-flexbox-gap")
  }

  // console.log("nonce", localized.nonce, localized.nonce_field, localized)
  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual"
  }
  // for ios touch -> enable active state for links
  document.addEventListener(
    "touchstart",
    function () {
      // console.log("touchstart")
    },
    false
  )

  // const DateTime = luxon.DateTime;

  let pathname = window.location.pathname
  // console.log('pathname', pathname);
  // console.log('history state', history.state);

  const blogName = document.querySelector('meta[name="name"]').content
  const blogDescription = document.querySelector('meta[name="description"]').content

  // console.log(blogName, blogDescription);

  // -----------------------------------------------
  const siteContainer = $(".site-container")
  const siteHeader = $(".site-header")
  const navContainer = $(".site-menu")

  const siteTitle = $(".site-header__title")
  const siteTitleLink = $(".site-header__link")
  const menuNavLinks = $(".menu-main > li.menu-item > a")
  const menuNavSubLinks = $(".sub-menu > li.menu-item > a")
  const imprintTitle = $(".imprint-title")

  const contentContainer = $(".content-container")
  const playButtonOn = $(".play-button--on")
  const playButtonOff = $(".play-button--off")
  // const dateTimeDisplay = $(".date-time")
  const dateTimeInput = $(".date-time-input")
  const locationTypes = $(".location-types")
  const temperatureDisplay = $(".temperature")
  const humidityDisplay = $(".humidity")
  const pressureDisplay = $(".pressure")
  const salinityDisplay = $(".salinity")
  const waterTempDisplay = $(".water-temp")

  // -----------------------------------------------------------------------------------------------------

  const $body = $("body")
  const $mainMenu = $(".menu-main")
  const homeID = $body.data("home-id").toString() // post id of the home page as string
  const blogID = $body.data("blog-id").toString() // post id of the blog page as string

  setLandscape()
  touchSupport()

  // set background color from customizer
  document.documentElement.style.setProperty("--bgcolor", $("body").data("bgcolor"))

  calcVhPropertyFromClientHeight() // for mobile viewport height on ios
  setAdminBarHeight()
  navAndTitleHeight()
  checkMobileView()

  // hide and fade in nav and content elements with visibility hidden
  contentContainer.hide()
  contentContainer.css("visibility", "visible")
  contentContainer.fadeIn()

  navContainer.hide()
  navContainer.css("visibility", "visible")
  navContainer.fadeIn(400)

  setTimeout(() => {
    // safety
    navAndTitleHeight()
  }, 100)

  const postsContainer = $(".posts-container")
  let postItemContainers = $(".post-item-container")

  postItemContainers.hide() // hide all post items

  let journalSwiper // swiper on blog page for blog posts
  let numberOfBlogPages = 1

  let nextFileRequested = false // disregard time updates from the audioplayer while request to play next file. time continues to count...
  const soundTypes = ["air", "soil", "water"]
  let activeSoundType = 0 // 0 = air, 1 = soil, 2 = water
  let locationSwiper
  // get data from location items
  let locations = []
  const locationSelector = $(".location-infos")
  const locationItems = $(".location-info-item")
  locationItems.each(function () {
    locations.push({
      name: $(this).data("name"),
      ch1: $(this).data("ch1"),
      ch2: $(this).data("ch2")
    })
  })

  // console.log(locations)

  // score loaded callback
  score.onload = function () {
    // take index 31, because of crappy data
    const firstDate = DateTime.fromSeconds(score.score[31].time).setZone(fixedZone).toFormat("yyyy-LL-dd'T'HH:mm")
    const lastDate = DateTime.fromSeconds(score.score[score.scoreLength - 1].time)
      .setZone(fixedZone)
      .toFormat("yyyy-LL-dd'T'HH:mm")

    // set min max date time in input field
    dateTimeInput.attr("min", firstDate)
    dateTimeInput.attr("max", lastDate)

    console.log("score loaded", firstDate, lastDate)
  }

  // audio stuff -----------------------------------------------------------------------------------
  // web audio
  var AudioContext = window.AudioContext || window.webkitAudioContext
  var audioContext = new AudioContext()
  let playStatus = false
  let audioPlayer = new AudioPlayer(audioContext)
  let playTime = 0
  // active player has stoppped
  audioPlayer.onstop = function (player) {
    // console.log("onstop: player", player) // if source set errror -> active player stop event is not correct (should be stop from inactive player)
  }

  audioPlayer.onerror = function (player) {
    // console.log("onerror: player", player)
  }

  audioPlayer.timeupdate = function (currentTime) {
    if (!nextFileRequested) {
      playTime = currentTime
      // calc duration to play a file. 5s less than audiofile length
      let duration = Math.max(Math.min(audioPlayer.duration, 120) - 5, 0)
      // duration = 10
      console.log(currentTime, audioPlayer.duration, duration)
      // play next file when duration has been reached

      if (currentTime >= duration) {
        nextFileRequested = true
        playTime = 0
        playNextFileFromNextLocation()
        setTimeout(() => {
          nextFileRequested = false
        }, 1000)
      }
    }
  }

  // choose a random Location for start
  let activeLocationIndex = getRandomLocationIndex()

  // select the active location
  showActiveLocation(activeLocationIndex)
  // set the location types
  setLocationTypes(locations[activeLocationIndex])

  // play on button handler
  playButtonOn.on("click", async function (e) {
    if (!playStatus) {
      await Tone.start()
      setPlayStatus(true)
      // console.log("date time", dateTimeInput.val())
      if (dateTimeInput.val() !== "") {
        // console.log("date is set -> play set file")
        audioPlayer.start(playTime)
      } else {
        playRandomFileForActiveLocationAndType()
      }
    }
  })

  playButtonOff.on("click", function () {
    if (playStatus) {
      setPlayStatus(false)
      audioPlayer.stop()
    }
  })

  // sound type select
  locationTypes.on("click", function () {
    locationTypes.removeClass("selected")
    $(this).addClass("selected")
    activeSoundType = $(this).index()
    if (playStatus) changeSoundType(activeSoundType)
  })

  locationSelector.on("change", function (e) {
    // const location = e.target.value
    activeLocationIndex = e.target.selectedIndex
    // console.log("location changed", activeLocationIndex, locations[activeLocationIndex])

    const location = locations[activeLocationIndex]
    showActiveLocation(activeLocationIndex)
    // set the location types
    setLocationTypes(location)

    // determine which channel should be played
    let channel = ""
    if (location.ch1 === soundTypes[activeSoundType]) {
      channel = "CH1"
    } else {
      channel = "CH2"
    }
    const nextIndex = score.nextIndexForLocationAndChannel(location.name, channel)

    const nextScoreEntry = score.score[nextIndex]
    const nextTime = nextScoreEntry.time

    // const dateTimeText = getDateTimeStringFromSeconds(nextScoreEntry.time)
    // dateTimeDisplay.text(dateTimeText)
    setDateTimeImputFromSeconds(nextScoreEntry.time)

    // play the file
    playTime = 0 // reset offset playtime
    const path = `${localized.template_url}/data/${location.name}/${channel}/`
    playFile(path + nextScoreEntry[channel])

    // get environ data
    updateEnvDataDisplayForLocation(activeLocationIndex)
  })

  dateTimeInput.on("change", function (e) {
    const date = e.target.value // "2017-06-01T08:30"

    // time in seconds
    const seconds = DateTime.fromFormat(date, "yyyy-LL-dd'T'HH:mm", {
      zone: fixedZone
    }).toSeconds()
    // console.log("play from seconds", seconds, date)

    // current location
    const location = locations[activeLocationIndex]
    // determine which channel should be played
    let channel = ""
    if (location.ch1 === soundTypes[activeSoundType]) {
      channel = "CH1"
    } else {
      channel = "CH2"
    }

    let prevIndex = -1
    let nextIndex = 0
    let nextScoreEntry
    let loopAround = false
    score.index = -1 // reset score index
    do {
      nextIndex = score.nextIndexForLocationAndChannel(location.name, channel)
      nextScoreEntry = score.score[nextIndex]
      // console.log("next index", nextIndex, seconds, nextScoreEntry.time, seconds < nextScoreEntry.time)

      if (prevIndex < nextIndex) prevIndex = nextIndex
      else loopAround = true
    } while (seconds > nextScoreEntry.time && !loopAround)

    if (loopAround) {
      nextScoreEntry = score.score[prevIndex]
    }

    // console.log(nextScoreEntry)
    const nextTime = nextScoreEntry.time

    // const dateTimeText = getDateTimeStringFromSeconds(nextScoreEntry.time)
    // dateTimeDisplay.text(dateTimeText)
    setDateTimeImputFromSeconds(nextScoreEntry.time)

    // play the file
    playTime = 0 // reset offset playtime
    const path = `${localized.template_url}/data/${location.name}/${channel}/`
    playFile(path + nextScoreEntry[channel])

    // get environ data
    updateEnvDataDisplayForLocation(activeLocationIndex)
  })

  // scroll down handler in page and blog pages -> dynamically added
  $(document).on("click", ".scroll-down", function (e) {
    e.preventDefault()
    const title = $(this).parent().parent().children(".page-title")
    $("html, body").animate(
      {
        scrollTop: title.offset().top - (adminBarHeight !== 46 ? adminBarHeight : 0)
      },
      800 //speed
    )
  })

  // menu and title navigation ------------------------------------------------------------------------------------------------
  menuNavLinks.add(siteTitleLink).on("click", function (e) {
    // menuNavLinks.on('click', function (e) {

    const parentLi = $(this).parent()
    const isPage = parentLi.hasClass("menu-item-object-page")
    const isProject = parentLi.hasClass("menu-item-object-arbeit")
    const isArchive = parentLi.hasClass("menu-item-type-post_type_archive")
    const id = getIDFromClasslist(parentLi)
    // console.log("wpse id", id, homeID, id === homeID)

    if (isPage) {
      // links in the menu are either pages (info page )or project archive
      e.preventDefault()

      const slug = $(this).attr("href").split(`${window.location.origin}/`)[1]

      // history only gets updated when clicking on a different menu item, and not when navigating by url (gotolocation)
      const ref = `${window.location.origin}/${slug}`

      if (window.location.href !== ref) {
        window.history.pushState(null, null, ref)
        // console.log("updated history ", ref);

        // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
        if (slug !== "") {
          // not home -> page | blogname
          document.title = $(this).text()?.toUpperCase() + " | " + blogName
        } else {
          // is home -> blogname | blogdescription
          document.title = blogName + " | " + blogDescription
        }
      }
      openPageLink($(this))
    }
  })

  // --------------------------------------------------------------------------------------------------------------------------------------------------------
  function openPageLink($link) {
    // console.log("open page link", $link.attr("href"))

    const parent = $link.parent()

    const pageID = getIDFromClasslist(parent)

    $("li.menu-item").removeClass("current-menu-item")
    parent.addClass("current-menu-item")

    // check if start page link and add current menu class to menu item
    const homeIDClass = `wpse-object-id-${homeID}`
    if (parent.hasClass(homeIDClass)) {
      // console.log("has home id class")
      $(`li.${homeIDClass}`).addClass("current-menu-item")
    }

    openPageWithID(pageID)
  }

  function openPageWithID(pageID) {
    // console.log("open page with id", pageID)
    if (pageID) {
      // hide all other page and post item content and the images
      if (pageID !== currentContentID) {
        $(".post-item-container").not(`#${pageID}`).hide() // hide page content
        $(".images-container__post-item-images").not(`#${pageID}-images`).fadeOut() // hide page images container
      }

      if (pageID === homeID) {
        siteContainer.addClass("page-home")
      } else {
        siteContainer.removeClass("page-home")
      }

      setCurrentContentID(pageID)

      if ($(`div#${pageID}`).length == 0) {
        // post-item-container for this page not yet existing -> get the page
        // console.log("page not yet..", pageID, homeID === pageID ? "is home page" : "is page")
        // page container does not exist yet -> load page with page id
        // home page is already loaded by index.php in any case

        // get page content
        $.getJSON(localized.root_url + "/wp-json/wp/v2/pages/" + pageID, page => {
          // console.log(page)

          // add page content ( no content for home page )
          postsContainer.append(`
            <div id="${pageID}" class="post-item-container post-type-page ${pageID === homeID ? "page-home " : ""}${pageID === blogID ? "page-blog " : ""}initialized" 
            data-title="${page.title.rendered}" data-history="${page.slug}" >

            ${
              pageID !== homeID // home page has no content
                ? `
                <div class="post-item-content">    
                  ${page.featuredImage ? `<div class="page-image">${page.featuredImage}<a href="#" class="scroll-down"><span class="scroll-icon"></span></a></div>` : ``}
                  ${page.title.rendered !== "" && pageID !== blogID ? `<h1 class="page-title">${page.title.rendered}</h1>` : ``}
                  ${page.content.rendered !== "" ? `<div class="page-content">${page.content.rendered}</div>` : ``}
                  ${
                    pageID === blogID
                      ? `
                  <div class="page-blog-swiper swiper">
                    <div class="swiper-wrapper">
                    </div>
                  </div>
                  <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div>
                  <div class="blog-page-control">
                      <div class="prev-blog-page">prev</div> 
                      <div class="next-blog-page">next</div>
                  </div>
                  `
                      : ""
                  }
                </div>`
                : ``
            }
            </div>
          `)

          const newPage = postsContainer.children(`#${pageID}`)
          newPage.hide()
          initPage(newPage) // get color data

          if (pageID === blogID) {
            createJournalSwiper()
          }

          newPage.imagesLoaded(function () {
            // addLazyloadToElement(newPage)
            newPage.fadeIn()
          })
        })
      } else {
        // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        // page container already exists -> show the page
        // - either loaded by php page template, then we have to initialize the content first
        // - or already loaded before by ajax and is initialized
        // init is only for home page. other pages are loaded by ajax
        // console.log("page exists", pageID)

        const page = $(`#${pageID}`)
        page.hide()

        if (!page.hasClass("initialized")) {
          // console.log("init existing page")
          // page not yet initialized because it was loaded by php

          if (pageID === homeID) {
            // console.log("is home!!!")
            // init location swiper
            locationSwiper = createLocationSwiper()
            // is page home
            page.imagesLoaded(function () {
              page.fadeIn()
              // no title fade in
            })
          } else {
            // all other pages
            initPage(page) // get colors
            page.imagesLoaded(function () {
              // console.log("page images loaded")
              page.fadeIn()
            })
          }

          page.addClass("initialized")
        } else {
          // page content existing and already initialized -> previously viewed
          // console.log("page already inited")
          page.fadeIn()
        }
      }
    }
  }

  function closeContent() {
    if (prevContentID !== -1) {
      // previous content exist -> browser back
      history.back()
    } else {
      // no previous content yet -> show home page
      siteTitleLink.trigger("click")
    }
  }

  function closePage() {
    siteTitleLink.trigger("click")
  }

  function scrollToElement($this, time) {
    // console.log("scroll to element", $this.offset().top)
    time = time ?? navChangingTime
    autoScrolling = true
    $("html, body").animate(
      {
        scrollTop: $this.offset().top - adminBarHeight
      },
      time,
      function () {
        autoScrolling = false
      }
    )
  }

  function scrollTop(top, time) {
    top = top || 0
    time = time ?? navChangingTime
    autoScrolling = true
    // console.log("scrolltop", top)
    $("html, body")
      .add(contentContainer)
      .animate(
        {
          scrollTop: top
        },
        time,
        function () {
          autoScrolling = false
        }
      )
  }

  function restoreScrollPosition() {
    const top = scrollYPositions[currentContentID] ?? 0
    scrollTop(top, 1)
  }

  function initPage(page) {
    // const bgcolor = page.data("background-color")
    // console.log("page bgcolor is", bgcolor)
    // page.css("background-color", bgcolor)
    // const color = page.data("color")
    // console.log("page color is", color, "!")
    // page.css("color", color)
  }

  function addLazyloadToElement($elem) {
    // https://github.com/tuupola/lazyload
    // $("img.lazyload").lazyload();
    // lazyload();

    // const elem = document.querySelector(`[id="${elemID}"]`);
    const elem = $elem[0]
    const images = elem.querySelectorAll("img.lazyload")
    // console.log("lazyload images", images, " in container ", $elem);

    new Lazyload(images, {
      root: null,
      rootMargin: "50px",
      threshold: 0
    })

    // add load event listener for lazyload images
    $elem.find(`img.lazyload`).on("load", function () {
      // console.log("img on elem xx loaded", $elem.attr('id'));
      $(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
    })
  }

  function openNav() {
    // console.log("open nav")
    // openClose.addClass("checked")
    $html.addClass("nav-open")
    navContainer.addClass("site-menu--nav-open")
    navOpen = true
    navChanging = true
    clearTimeout(navChangingTimeout)
    navChangingTimeout = setTimeout(() => {
      navChanging = false
    }, navChangingTime)
  }

  function closeNav(duration, scrollToElem) {
    duration = duration ?? navChangingTime
    // console.log("close nav", navOpen, duration, scrollToElem)

    // // remove classes may added by scrolling
    siteHeader.removeClass("site-header--visible")
    navContainer.removeClass("site-menu--visible")

    if (navOpen || scrollToElem) {
      navContainer.removeClass("site-menu--nav-open")

      clearTimeout(navChangingTimeout)
      navChangingTimeout = setTimeout(() => {
        navChanging = false
        navOpen = false
        $html.removeClass("nav-open")

        if (scrollToElem) {
          scrollToElement(scrollToElem)
        } else if (mobileView) {
          // restore scroll position
          scrollTop(scrollYPositions[currentContentID], 0)
        }
      }, duration)
    }
  }

  // handle pathname ------------------------------------------------------------------------------------------------
  gotoLocation(pathname)

  function gotoLocation(pathname) {
    // console.log("goto to location:", pathname)

    // get post info
    if (pathname === "/") {
      // is home page
      // console.log("is home")
      openPageLink(siteTitleLink)
      // --------------------------------------------------------
    } else {
      // not home
      // console.log("not home check menu items", $("li.menu-item").length)
      // first check if it's a menu item. exclude the lang menu item
      let found = false
      $("li.menu-item:not(.lang-item)").each(function () {
        if ($(this).children("a").attr("href").includes(pathname)) {
          found = true
          // console.log("menu link found", $(this).children("a").attr("href"))
          // if page
          if ($(this).hasClass("menu-item-object-page")) openPageLink($(this).children("a"))
          return false
        }
      })
      if (!found) {
        // was not a menu link
        // console.log("not menu link")
        // console.log("url is something else")
        // something other -> get post type for path
        $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${pathname}`, post => {
          // console.log(post)
          if (post.ID > 0) {
            // post found
            if (post.post_type == "page") {
              // page that's not in the menu
              openPageWithID(post.ID)
              // // hide all other page and post items
            } else if (post.post_type == "post") {
              // console.log("is post post")
              $(".post-item-container").not(`#${post.ID}`).hide()
              $("li.menu-item").removeClass("current-menu-item")
              $(`#${post.ID}`).fadeIn()
            } else {
              // hide all other post items
              $(".post-item-container").not(`#${post.ID}`).hide()
              $("li.menu-item").removeClass("current-menu-item")
              $(`#${post.ID}`).fadeIn()
            }
          } else {
            // is not a post
          }
        })
      }
    }
  }

  // return a non consecutive random location index (0-2)
  function getRandomLocationIndex() {
    let prevIndex = localStorage.getItem("randomLocationIndex")
    let randomLocationIndex = Math.floor(Math.random() * locations.length)
    if (prevIndex) {
      while (prevIndex == randomLocationIndex) randomLocationIndex = Math.floor(Math.random() * locations.length)
    }
    localStorage.setItem("randomLocationIndex", randomLocationIndex)
    return randomLocationIndex
  }

  function getDateTimeStringFromSeconds(seconds) {
    return DateTime.fromSeconds(seconds).setZone(fixedZone).toFormat("dd.LL.yyyy HH:mm")
  }

  function setDateTimeImputFromSeconds(seconds) {
    const dateTime = DateTime.fromSeconds(seconds).setZone(fixedZone).toFormat("yyyy-LL-dd'T'HH:mm")

    // const dateTime = DateTime.fromSeconds(seconds).setZone(fixedZone).toISO({
    //   suppressSeconds: true,
    //   suppressMilliseconds: true,
    //   includeOffset: false
    // })

    // console.log(dateTime)
    dateTimeInput.val(dateTime)
  }

  function showActiveLocation(activeLocationIndex) {
    // locationItems.removeClass("show")
    // locationItems.eq(activeLocationIndex).addClass("show")
    // console.log("active location is", activeLocationIndex)
    locationItems.prop("selected", false)
    locationItems.eq(activeLocationIndex).prop("selected", true)
    // set swiper slide to location, if swiper initialized
    if (locationSwiper) {
      // console.log("location swiper...", locationSwiper)
      locationSwiper.slideTo(activeLocationIndex)
    }
  }

  function setLocationTypes(location) {
    // show / hide sound type according to location properies
    locationTypes.each(function () {
      if ($(this).data("type") === location.ch1 || $(this).data("type") === location.ch2) {
        $(this).addClass("show")
      } else {
        $(this).removeClass("show")
      }
    })
    // check and set activeSoundType
    const activeType = locationTypes.eq(activeSoundType)
    locationTypes.removeClass("selected")

    if (activeType.hasClass("show")) {
      // active type can be selected
      activeType.addClass("selected")
    } else {
      // active type is not available -> choose another type
      const showedTypes = locationTypes.filter(".show")
      const index = Math.floor(Math.random() * showedTypes.length)
      const selectedType = showedTypes.eq(index)
      selectedType.addClass("selected")
      activeSoundType = selectedType.index()
    }
  }

  function playRandomFileForActiveLocationAndType() {
    const loc = locations[activeLocationIndex]
    // determine which channel should be played
    let channel = ""
    if (loc.ch1 === soundTypes[activeSoundType]) {
      channel = "CH1"
    } else {
      channel = "CH2"
    }
    // random index for the current location
    const randomIndex = score.randomIndexForLocationAndChannel(loc.name, channel)
    const scoreEntry = score.score[randomIndex]
    // update date and time display
    // const dateTimeText = getDateTimeStringFromSeconds(scoreEntry.time)
    // dateTimeDisplay.text(dateTimeText)
    setDateTimeImputFromSeconds(scoreEntry.time)

    // play file
    const path = `${localized.template_url}/data/${loc.name}/${channel}/`
    // console.log(path)
    playFile(path + scoreEntry[channel])

    // get environ data
    updateEnvDataDisplayForLocation(activeLocationIndex)
  }

  function playNextFileFromNextLocation() {
    const currentIndex = score.index // store index
    const currentTime = score.score[currentIndex].time // store curren time
    let nextTime = 0
    let nextLocation, channel, nextIndex, nextScoreEntry
    let locationsTried = 0

    // try to play the next location. if time is earlier (score loop around) -> try to play next location

    do {
      score.index = currentIndex // reset the score index to current

      activeLocationIndex++
      if (activeLocationIndex >= locations.length) activeLocationIndex = 0

      nextLocation = locations[activeLocationIndex]
      showActiveLocation(activeLocationIndex)
      // set the location types
      setLocationTypes(nextLocation)

      // determine which channel should be played
      channel = ""
      if (nextLocation.ch1 === soundTypes[activeSoundType]) {
        channel = "CH1"
      } else {
        channel = "CH2"
      }
      nextIndex = score.nextIndexForLocationAndChannel(nextLocation.name, channel)

      nextScoreEntry = score.score[nextIndex]
      nextTime = nextScoreEntry.time
      locationsTried++
    } while (locationsTried < locations.length - 1 && nextTime < currentTime)

    // const dateTimeText = getDateTimeStringFromSeconds(nextScoreEntry.time)
    // dateTimeDisplay.text(dateTimeText)
    setDateTimeImputFromSeconds(nextScoreEntry.time)

    // play the file
    const path = `${localized.template_url}/data/${nextLocation.name}/${channel}/`
    playFile(path + nextScoreEntry[channel])

    // get environ data
    updateEnvDataDisplayForLocation(activeLocationIndex)
  }

  function playFile(filename, time) {
    console.log("play file", filename, time ?? 0)

    let promise = audioPlayer.setSource(filename)

    promise
      .then(() => {
        if (playStatus) {
          audioPlayer.start(time ? time : 0)
        }
        // console.log("radio: set source ok");
      })
      .catch(err => {
        // console.log("play file error", err)
        setPlayStatus(false)
        /// error handling -> try playing an other file
        // if (playStatus) tryOtherLocations()
        // if (radioMode) tryOtherLocations();
        // else playNextFile(); // goto next hour
      })
  }

  function setPlayStatus(status) {
    // console.log("set play status", status);

    if (status) {
      playStatus = true
      playButtonOn.addClass("selected")
      playButtonOff.removeClass("selected")
    } else {
      playStatus = false
      playButtonOn.removeClass("selected")
      playButtonOff.addClass("selected")
    }
  }

  function changeSoundType(typeIndex) {
    const location = locations[activeLocationIndex]
    // determine which channel should be played
    let channel = ""
    if (location.ch1 === soundTypes[typeIndex]) {
      channel = "CH1"
    } else {
      channel = "CH2"
    }
    // tbd get the next entry if requested channel is missing in current entry
    // tbd
    let scoreEntry = score.score[score.index]
    // console.log("change sound type for this entry", scoreEntry)
    if (!scoreEntry[channel]) {
      // requested channel is missing -> find it
      // console.log("channel is missing", channel)
      const ind = getIndexOfNearestMissingChannel(location.name, channel)
      if (ind >= 0) {
        scoreEntry = score.score[ind]
        // console.log("found missing channel", scoreEntry)
        // update date and time display
        // const dateTimeText = getDateTimeStringFromSeconds(scoreEntry.time)
        // dateTimeDisplay.text(dateTimeText)
        setDateTimeImputFromSeconds(scoreEntry.time)

        // play the file
        const path = `${localized.template_url}/data/${location.name}/${channel}/`
        playFile(path + scoreEntry[channel], playTime)
      } else {
        // console.log("channel not found eror")
      }
    } else {
      // console.log("all gooood", channel)
      // update date and time display
      // const dateTimeText = getDateTimeStringFromSeconds(scoreEntry.time)
      // dateTimeDisplay.text(dateTimeText)
      setDateTimeImputFromSeconds(scoreEntry.time)

      // play the file
      const path = `${localized.template_url}/data/${location.name}/${channel}/`
      playFile(path + scoreEntry[channel], playTime)
    }
  }

  function getIndexOfNearestMissingChannel(locationName, channel) {
    let index = score.index
    const timeNow = score.score[index].time
    let timeAfter, timeBefore, timeDiffAfter, timeDiffBefore, timeAfterIndex, timeBeforeIndex
    // check entry in the future
    while (index < score.scoreLength && (score.score[index].loc !== locationName || !score.score[index].hasOwnProperty(channel))) {
      index++
    }
    if (index < score.scoreLength) {
      // entry found get timestamp
      timeAfterIndex = index
      timeAfter = score.score[index].time
      timeDiffAfter = timeAfter - timeNow
    }
    // then check entry in the past
    index = score.index // reset the index to current index
    while (index >= 0 && (score.score[index].loc !== locationName || !score.score[index].hasOwnProperty(channel))) {
      index--
    }
    if (index >= 0) {
      // entry found get timestamp
      timeBeforeIndex = index
      timeBefore = score.score[index].time
      timeDiffBefore = timeNow - timeBefore
    }
    if (timeAfterIndex && timeBeforeIndex) {
      // both found
      // console.log("found later and earlier entry", timeBeforeIndex, score.index, timeAfterIndex)
      if (timeDiffAfter < timeDiffBefore) {
        score.index = timeAfterIndex
        return timeAfterIndex
      } else {
        score.index = timeBeforeIndex
        return timeBeforeIndex
      }
    } else if (timeDiffAfter) {
      score.index = timeAfterIndex
      return timeAfterIndex
    } else if (timeDiffBefore) {
      score.index = timeBeforeIndex
      return timeBeforeIndex
    } else {
      // channel not found -> error
      return -1
    }
  }

  function updateEnvDataDisplayForLocation(locationIndex) {
    const loc = locations[locationIndex]
    let dataIndex = score.nextIndexForLocationEnvData(loc.name)

    const envData = score.score[dataIndex]
    console.log("env data", loc, envData)

    dataIndex = score.nextIndexForWaterData()
    const waterData = score.score[dataIndex]
    console.log("water data", waterData)

    const data = {
      ...envData,
      ...waterData
    }

    // console.log("env data", locationIndex, data)

    if (data.temperature && parseInt(data.temperature) !== -200) {
      temperatureDisplay.text(`air temperature: ${data.temperature}° C`)
    } else {
      temperatureDisplay.text(`air temperature: --`)
    }
    if (data.humidity && parseInt(data.temperature) !== -200) {
      humidityDisplay.text(`air humidity: ${data.humidity}%`)
    } else {
      humidityDisplay.text(`air humidity: --`)
    }
    if (data.pressure && parseInt(data.temperature) !== -200) {
      pressureDisplay.text(`air pressure: ${data.pressure} hPa`)
    } else {
      pressureDisplay.text(`air pressure: --`)
    }
    if (data.salinity) {
      salinityDisplay.text(`water salinity: ${data.salinity} psu`)
    } else {
      salinityDisplay.text(`water salinity: --`)
    }
    if (data.temp_water) {
      waterTempDisplay.text(`water temperature: ${data.temp_water}° C`)
    } else {
      waterTempDisplay.text(`water temperature: --`)
    }
  }

  // recorder location slider
  function createLocationSwiper() {
    // console.log("create location swiper. initial index: ", activeLocationIndex)

    const locationSwiper = new Swiper(".page-home .location-swiper", {
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      speed: 1800,
      allowTouchMove: false,
      initialSlide: activeLocationIndex
    })

    return locationSwiper
  }

  // blog posts swiper
  function createJournalSwiper() {
    // console.log("create journal swiper")
    journalSwiper = new Swiper(".page-blog-swiper", {
      autoHeight: true,
      // history: {
      //   root: localized.root_url,
      //   key: "blog",
      //   keepQuery: true
      // },
      init: true, // dont init because slides still missing and history is on
      navigation: {
        nextEl: ".swiper-button-next-nonexist",
        prevEl: ".swiper-button-prev"
      },
      on: {
        slideChangeTransitionEnd: swiper => {
          // console.log("slided to index", swiper.activeIndex);
          if (swiper.activeIndex + 1 == numberOfBlogPages) {
            $(".swiper-button-next").addClass("swiper-button-next-disabled")
          } else {
            $(".swiper-button-next").removeClass("swiper-button-next-disabled")
          }
        },
        init: swiper => {
          // console.log("journal swiper init", swiper.params)
        }
      }
    })

    const jqxhr = $.getJSON(localized.root_url + "/wp-json/wp/v2/posts?per_page=1&page=1&filter[orderby]=date&order=desc", posts => {
      createSlidesWithPosts(posts, 1)
    })
    // get number of blog pages
    jqxhr.always(function () {
      //here is how to access the response header
      // console.log("number of pages " + jqxhr.getResponseHeader('x-wp-totalpages'));
      numberOfBlogPages = jqxhr.getResponseHeader("x-wp-totalpages")
    })
  }

  let swiperNextClicked = false // disable clicking next during slide loading
  $(document).on("click", ".page-blog .swiper-button-next", () => {
    if (!swiperNextClicked) {
      const index = Math.max(0, journalSwiper.realIndex)
      // console.log("get next clicked. current index is", index)
      const nextPageNbr = index + 2

      if (nextPageNbr <= numberOfBlogPages) {
        // check if the page is already there
        const $page = $(`[data-page="${nextPageNbr}"]`)
        if ($page.length > 0) {
          // page already exists -> slide to it
          // console.log("slide already therer");
          journalSwiper.slideTo(nextPageNbr - 1)
        } else {
          swiperNextClicked = true
          // page slide not yet there
          // console.log("slide not yet -> get next page", nextPageNbr);
          $.getJSON(localized.root_url + "/wp-json/wp/v2/posts?per_page=1&page=" + nextPageNbr + "&filter[orderby]=date&order=desc", posts => {
            createSlidesWithPosts(posts, nextPageNbr)
            swiperNextClicked = false
          })
        }
      }
    }
  })

  function createSlidesWithPosts(posts, slideNbr) {
    // console.log("posts", posts)

    // add slides to the swiper
    journalSwiper.appendSlide(
      `${posts
        .map(
          item =>
            `<div class="blog-item-container swiper-slide ${slideNbr === 1 ? "swiper-slide-active" : ""}" data-page="${slideNbr}" data-history="${item.slug}">
                
                <div class="blog-featured-image">
                    ${item.featured_image ? item.featured_image : ""}
                    ${item.content.rendered !== "" ? '<a href="#" class="scroll-down"><span class="scroll-icon"></span></a>' : ""}
                </div>
                
                <div class="blog-item-header page-title">
                    <div class="blog-date">
                        ${DateTime.fromISO(item.date, {
                          zone: "Europe/Berlin"
                        }).toFormat("dd'.'LL'.'yy")}
                    </div>
                    <h1 class="blog-title">
                    ${item.title.rendered}
                    </h1>
                </div>

                <div class="blog-content">
                    ${item.content.rendered}
                </div>

                <div class="blog-comment-container">

                  <div class="blog-comment-section-title">Comments</div>

                      ${item.comments
                        ?.map(
                          comment =>
                            `<div class="blog-comment-item">
                              <div class="blog-comment-content">${comment.comment_content}</div>
                              <div class="blog-comment-author">von ${comment.comment_author}, ${DateTime.fromFormat(comment.comment_date, "yyyy-MM-dd HH:mm:ss").toFormat("dd'.'LL'.'yy")}</div>
                          </div>`
                        )
                        .join("")}

                    <form>
                        <input type="hidden" id="id-${item.id}" value=${item.id} />

                        <div>
                            <label htmlFor="name">Name*</label>
                            <input id="name-${item.id}" type="text" required />
                        </div>
                        
                        <div>
                            <label htmlFor="email">Email*</label>
                            <input
                            id="email-${item.id}"
                            type="email"
                            required
                            />
                        </div>
                        <div>
                            <label htmlFor="comment">Comment*</label>
                            <textarea
                            id="comment-${item.id}"
                            required></textarea>
                        </div>
                        <input type="submit" value="Submit" />
                    </form>

                </div>

            </div>`
        )
        .join("")}`
    )

    // journalSwiper.params.history = {
    //   enabled: true,
    //   root: localized.root_url,
    //   key: "blog",
    //   keepQuery: true
    // }
    // journalSwiper.init()

    // console.log(journalSwiper.params.history)

    // set css width and height according to image aspect
    const img = $(`[data-page=${slideNbr}] > div > img`)
    img.on("load", function () {
      setImgWidthAndHeightCSS(img)
    })

    setTimeout(() => {
      journalSwiper.update()
    }, 100)

    if (slideNbr && slideNbr > 0) {
      setTimeout(() => {
        journalSwiper.slideTo(slideNbr - 1)
      }, 200)
    }
  }

  // submit comment handler
  $(document).on("submit", ".blog-comment-container > form", function (e) {
    e.preventDefault()
    console.log("form submitted", e.target.elements)
    const [postId, name, email, comment] = e.target.elements

    const data = JSON.stringify({
      post: postId.value,
      author_name: name.value,
      author_email: email.value,
      content: comment.value
    })

    const ACTION_URL = localized.root_url + "/wp-json/wp/v2/comments"
    fetch(ACTION_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: data
    })
      .then(response => {
        if (response.ok === true) {
          // Submitted successfully!
          console.log("commment submit ok", postId.value)
          // clear inputs
          $(`.blog-comment-container #name-${postId.value}`).val("")
          $(`.blog-comment-container #email-${postId.value}`).val("")
          $(`.blog-comment-container #comment-${postId.value}`).val("")
          alert("Your comment has been submitted.")
        }

        return response.json()
      })
      .catch(error => console.error("Error:", error))
  })

  function setImgWidthAndHeightCSS(img) {
    // console.log(img, img[0].naturalHeight, img.prop('naturalWidth'));
    const imgW = img.prop("naturalWidth")
    const imgH = img[0].naturalHeight

    const imgAspect = imgW / imgH
    const windowAspect = $(window).width() / ($(window).height() - adminBarHeight)
    // console.log("img aspect", imgAspect, "window aspect", windowAspect);

    if (imgAspect <= windowAspect) {
      // img is more portrait than window
      img.css("width", "100%")
      img.css("height", "auto")
      img.parent().height(img.height())
    } else {
      // image is more landscapy than window
      img.parent().css("height", "")
      img.css("width", "auto")
      img.css("height", "100%")
    }
  }

  function setCurrentContentID(id) {
    prevContentID = currentContentID
    currentContentID = id
    // console.log("current content:", currentContentID, prevContentID)
  }

  let resizeTimeout = null
  let resizeTimeout2 = null

  window.addEventListener("resize", () => {
    checkMobileView()

    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      touchSupport()
      onOrientationChange()
      setAdminBarHeight()
      calcVhPropertyFromClientHeight()
      navAndTitleHeight()
      checkMobileView()
      navAndTitleHeight()
    }, 500)

    clearTimeout(resizeTimeout2)
    resizeTimeout2 = setTimeout(() => {
      // for ios safari to get correct window height
      calcVhPropertyFromClientHeight()
    }, 1000)
  })

  function touchSupport() {
    const touchsupport = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

    if (!touchsupport) {
      // browser doesn't support touch
      // document.documentElement.className += " non-touch"
      $("html").addClass("non-touch")
      $("html").removeClass("touch")
    } else {
      $("html").removeClass("non-touch")
      $("html").addClass("touch")
    }
  }

  function checkMobileView() {
    if ($(window).width() < mobileBreakpoint) {
      mobileView = true
      $("html").addClass("mobile-view")
      $("html").removeClass("desktop-view")
    } else {
      mobileView = false
      $("html").removeClass("mobile-view")
      $("html").addClass("desktop-view")
    }
    // console.log("window width", $(window).width());
  }

  function onOrientationChange() {
    // detect orientation change
    if ((orientationLandscape && window.innerWidth < window.innerHeight) || (!orientationLandscape && window.innerWidth >= window.innerHeight)) {
      setLandscape()
    }
  }

  function setLandscape() {
    // store new orientation
    orientationLandscape = window.innerWidth >= window.innerHeight

    if (orientationLandscape) {
      $("html").addClass("orientation-landscape")
      $("html").removeClass("orientation-portrait")
    } else {
      $("html").removeClass("orientation-landscape")
      $("html").addClass("orientation-portrait")
    }
    // console.log("orientation changed, landscape:", orientationLandscape);
  }

  function setAdminBarHeight() {
    let wpabh = 0
    const adminBar = $("#wpadminbar")
    if (adminBar.length) {
      wpabh = adminBar.outerHeight()
    }
    adminBarHeight = wpabh
    $(":root").css("--adminBarHeight", `${adminBarHeight}px`)
  }

  function navAndTitleHeight() {
    siteTitleHeight = siteTitle.outerHeight()
    $(":root").css("--siteTitleHeight", `${siteTitleHeight}px`)
    // console.log("title height", siteTitleHeight)

    // get nav height only when nav closed
    if (!navOpen) {
      navHeight = navContainer.outerHeight()
      $(":root").css("--navHeight", `${navHeight}px`)
    }
  }

  function calcVhPropertyFromClientHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = document.documentElement.clientHeight * 0.01
    const vh = window.innerHeight * 0.01
    // console.log("height: ", vh * 100);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    // if (debug) console.log("client height: ", document.documentElement.clientHeight);

    //
    // const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    // const h = document.documentElement.clientHeight
    // console.log("ios vh:", vh * 100, "vh:", h)
  }

  // reload page on broswer back / forward
  window.onpopstate = function (event) {
    // console.log("on popstate", event);
    gotoLocation(window.location.pathname)
  }

  jQuery(document.body).on("post-load", function () {
    // New content has been added to the page.
    // console.log("post-load")
  })

  // for desktop: overflow auto for .content-container not body -> get scroll position from content-container
  document.querySelector(".content-container").addEventListener("scroll", function (e) {
    // console.log("scroll in content container", e.target.scrollTop)
    scrollYPositions[currentContentID] = e.target.scrollTop
  })

  function getIDFromClasslist(elem) {
    // get page id from menu item classlist (wpse-object-id-XX)
    let classlist = elem.attr("class").split(/\s+/)
    let pageID
    $.each(classlist, function (index, item) {
      if (item.startsWith("wpse-object-id-")) {
        const splits = item.split("wpse-object-id-")
        if (splits.length > 1) pageID = splits[1]
        return false
      }
    })
    return pageID
  }

  function checkFlexGap() {
    // create flex container with row-gap set
    var flex = document.createElement("div")
    flex.style.display = "flex"
    flex.style.flexDirection = "column"
    flex.style.rowGap = "1px"
    flex.style.position = "absolute"

    // create two, elements inside it
    flex.appendChild(document.createElement("div"))
    flex.appendChild(document.createElement("div"))

    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex)
    var isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex)

    return isSupported
  }

  function observeElement($this) {
    if (observer) {
      observer.disconnect()
    } else {
      observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // console.log("ENTER")
          } else {
            // console.log("LEAVE")
          }
        },
        {
          root: null,
          threshold: 0.01 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    observer.observe($this[0])
  }
})
